<template>
  <div>
    <div
      class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded"
      :class="targets.length < 10 ? '' : 'pr-2'"
    >
      <div
        class="flex flex-row items-center focus:outline-none w-tableIndex mr-2"
      >
        <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
      </div>
      <div class="flex flex-grow w-1/4">
        <button
          class="flex flex-row items-center focus:outline-none"
          @click="sort('competencyName', 0)"
          data-testid="hader-1"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">COMPETENCY</p>
          <img
            src="@/assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
      </div>
      <button
        v-if="slicingIndex > 0"
        class="bg-white h-btn w-30 rounded flex justify-center items-center mr-5"
        @click="decreaseSlice"
        data-testid="left-button"
      >
        <img :src="arrow" class="transform rotate-180" />
      </button>
      <div v-else class="w-30 mr-5" />
      <div
        v-if="isOrganizational"
        class="flex flex-col w-1/6 flex-grow justify-center"
      >
        <button
          class="flex flex-row items-center justify-center focus:outline-none"
          @click="sort('organizationalTargets', 1)"
        >
          <p
            class="text-sm-2 text-grey-light mr-0.4 min-h-32 flex items-center"
          >
            ORGANIZATION
          </p>
          <img
            src="@/assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
      </div>
      <div
        v-else
        v-for="(item, index) in slicedColumns"
        :key="item.targetId"
        class="flex flex-col w-1/6 flex-grow justify-center"
      >
        <button
          class="flex flex-row items-center justify-center focus:outline-none"
          @click="sort('', index + 1)"
        >
          <p
            class="text-sm-2 text-grey-light mr-0.4 min-h-32 flex items-center"
          >
            {{ item.jobRoleName }}
          </p>
          <img
            src="@/assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="
              rotationMatrix[index + 1] !== 1 ? 'transform rotate-180' : null
            "
          />
        </button>
      </div>

      <button
        v-if="slicingIndex * 3 < headerColumns.length - 3"
        class="bg-white h-btn w-30 rounded flex ml-5 justify-center items-center"
        @click="increaseSlice"
        data-testid="right-button"
      >
        <img :src="arrow" />
      </button>
      <div v-else class="w-30 ml-5" />
    </div>
    <div>
      <VirtualList
        :data-key="'competencyId'"
        :data-sources="mappedList"
        :data-component="itemComponent"
        :extra-props="{
          slicingIndex: slicingIndex,
          jobRoles: this.jobRoles,
        }"
      />
    </div>
  </div>
</template>

<script>
import arrow from '@/assets/img/icons/arrow-right.svg';
import { mapState, mapGetters, mapActions } from 'vuex';
import Item from './Item.vue';
import VirtualList from 'vue-virtual-scroll-list';

export default {
  name: 'TeamTable',
  components: { VirtualList },
  data: () => ({
    itemComponent: Item,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    slicingIndex: 0,
    headerColumns: [],
    arrow,
  }),
  watch: {
    targets() {
      this.mappedList = [...this.targets];
      this.headerColumns = this.targets[0].targets;
      // REVERT THIS IF THINGS BREAK HERE
      // .filter(
      //   (el) => this.jobRoles.indexOf(el.jobRoleId) > -1
      // );
    },
  },
  computed: {
    ...mapGetters({
      jobRoles: 'assessmentSetup/getAssignedJobRolesIds',
    }),
    ...mapState({
      targets: (state) => state.assessmentSetup.targets,
      id: (state) => state.assessmentSetup.cloneId,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
    }),
    isIndividual() {
      return this.generalInfo?.surveyType === 0;
    },
    isOrganizational() {
      return this.generalInfo?.surveyType === 1;
    },
    slicedColumns() {
      return this.headerColumns.slice(
        this.slicingIndex * 3,
        this.slicingIndex * 3 + 3
      );
    },
  },
  async mounted() {
    if (this.isIndividual) await this.getIndividualTargets({ id: this.id });
    if (this.isOrganizational) await this.getOrganizationalTargets({ id: this.id });
  },
  methods: {
    ...mapActions({
      getIndividualTargets: 'assessmentSetup/getIndividualTargets',
      getOrganizationalTargets: 'assessmentSetup/getOrganizationalTargets',
    }),
    increaseSlice() {
      this.rotationMatrix = [1, 1, 1, 1];
      this.slicingIndex++;
    },
    decreaseSlice() {
      this.rotationMatrix = [1, 1, 1, 1];
      this.slicingIndex--;
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      if (key == 'competencyName') {
        this.mappedList.sort(
          (a, b) =>
            this.rotationMatrix[index] *
            a.competencyName.localeCompare(b.competencyName)
        );
      } else if (key === 'organizationalTargets') {
        this.mappedList.sort((a,b) => {
          return this.rotationMatrix[index] * (a?.targets[0]?.value - b?.targets[0]?.value);
        })
      } else {
        this.mappedList.sort((a, b) => {
          let first = a.targets.find(
            (el) => el.jobRoleId === this.headerColumns[index - 1].jobRoleId
          );
          let second = b.targets.find(
            (el) => el.jobRoleId === this.headerColumns[index - 1].jobRoleId
          );
          let result = first.value - second.value;
          return this.rotationMatrix[index] * result;
        });
      }
    },
  },
};
</script>
