<template>
  <div class="mb-3">
    <div class="flex justify-between items-center">
      <p class="font-bold text-mdlh text-grey-dark-1 mb-2">Settings</p>
      <Button
        v-if="!viewOnly"
        text="Edit"
        type="secondary"
        size="medium"
        :iconLeft="edit"
        :onClick="handleClick"
        data-testid="edit-button"
      />
    </div>
    <div class="flex mb-2 space-x-4">
      <div>
        <p class="font-medium text-sm-2 text-grey-light">Launch Date</p>
        <p class="text-mdh text-grey-dark-1">
          {{ formatDate(generalInfo.openingTime) }}
        </p>
      </div>
      <div>
        <p class="font-medium text-sm-2 text-grey-light">Close Date</p>
        <p class="text-mdh text-grey-dark-1">
          {{ formatDate(generalInfo.closingTime) }}
        </p>
      </div>
    </div>
    <SettingsPreview />
  </div>
</template>

<script>
import { mapState } from "vuex";
import edit from "@/assets/img/icons/edit.svg";
import Button from "../../../../components/Button/Button.vue";
import SettingsPreview from "./PreviewTables/Settings";
import moment from "moment";
export default {
  name: "Step6",
  components: { SettingsPreview, Button },
  data: () => ({ edit }),
  props: {
    viewOnly: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      generalInfo: (state) => state.assessmentSetup.generalInfo,
    }),
  },
  methods: {
    handleClick() {
      if(this.viewOnly) return;
      this.$router.push("/assessments/settings");
    },
    formatDate(date) {
      return moment(date).format("ddd, D MMM YYYY");
    },
  },
};
</script>
