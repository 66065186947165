<template>
  <div>
    <div class="flex justify-between items-center">
      <p class="font-bold text-mdlh text-grey-dark-1 mb-2">
        Participants
      </p>
      <Button
        v-if="!viewOnly"
        text="Edit"
        type="secondary"
        size="medium"
        :iconLeft="edit"
        :onClick="handleClick"
        data-testid="edit-button"
      />
    </div>
    <div class="flex">
      <p class="font-bold text-mdh text-grey-dark-1 mb-2">
        {{ participants.length }} TOTAL
      </p>
      <p class="ml-0.4 font-medium text-mdh text-grey-dark-1 mb-2">
        participants
      </p>
    </div>
  </div>
</template>

<script>
import edit from "@/assets/img/icons/edit.svg";
import Button from "../../../../components/Button/Button.vue";
import { mapState } from "vuex";
export default {
  name: "Step2",
  data: () => ({ edit }),
  components: { Button },
  props: {
    viewOnly: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      participants: (state) => state.assessmentSetup.participants,
    }),
  },
  methods: {
    handleClick() {
      if(this.viewOnly) return;
      this.$router.push("/assessments/participants");
    },
  },
};
</script>

<style></style>
